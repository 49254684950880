<script setup lang="ts">
import { tv } from "tailwind-variants"

import { tvConfig } from "~/utils/tailwindVariantsConfig"

const props = defineProps<{
  icon: string
  label: string
  openOverride?: boolean
}>()

const open = ref(props.openOverride ?? true)

const classes = tv(
  {
    slots: {
      link: "group flex w-full items-center gap-2 rounded px-2 py-2 transition-colors relative cursor-pointer text-dusk-600 hover:bg-dusk-100 mb-2",
      icon: "transition-colors text-[24px] text-dusk-300 group-hover:text-dusk-600",
    },
  },
  tvConfig,
)

const slots = computed(() => classes())
</script>

<template>
  <div>
    <button :class="slots.link()" @click="open = !open">
      <Icon :name="icon" :class="slots.icon()" />
      {{ label }}
      <Icon
        name="mingcute:down-small-line"
        class="ml-auto text-[24px] transition-transform"
        :class="open ? 'rotate-180' : ''" />
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95">
      <div v-if="open" class="space-y-1 pl-8">
        <slot />
      </div>
    </transition>
  </div>
</template>
